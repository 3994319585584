<template>
  <div class="checkbox-detail">
    <h1 class="checkbox-detail__title">
      Проверки недвижимости на риски
    </h1>
    <p class="description">
      Данный отчет содержит полную информацию о недвижимости и собственниках:
    </p>
    <ul>
      <li>
        <span class="detail-icon">1</span>
        <b>ФИО всех собственников с 1998 года</b>
      </li>
      <li>
        <span class="detail-icon">2</span>
        <b>Серию и номер свидетельства о регистрации</b>
      </li>
      <li>
        <span class="detail-icon">3</span>
        <b>Даты перехода прав на объект</b>
      </li>
      <li>
        <span class="detail-icon">4</span>
        <b>Информация о предыдущих собственниках</b>
      </li>
      <li>
        <span class="detail-icon">5</span>
        <b>Обременения (арест/залог/ипотека)</b>
      </li>
      <li>
        <span class="detail-icon">6</span>
        <b>Кадастровая стоимость объекта</b>
      </li>
      <li>
        <span class="detail-icon">7</span>
        <b>Задолженность собственников</b>
      </li>
      <li>
        <span class="detail-icon">8</span>
        <b>Проверка действительности паспорта</b>
      </li>
      <li>
        <span class="detail-icon">9</span>
        <b>Проверка на банкротство собственника</b>
      </li>
      <li>
        <span class="detail-icon">10</span>
        <b>
          Наличие судебных разбирательств как по объекту, так и по собственнику
        </b>
      </li>
      <li>
        <span class="detail-icon">11</span>
        <b>Проверка объекта на наличие в списке на снос</b>
      </li>
      <li>
        <span class="detail-icon">12</span>
        <b>Проверка на массовую регистрацию по адресу</b>
      </li>
      <li>
        <span class="detail-icon">13</span>
        <b>Проверка на запрет регистрационных действий</b>
      </li>
      <li>
        <span class="detail-icon">14</span>
        <b>
          Указываем на риски, которые являются значимыми при совершении сделок
        </b>
      </li>
      <li>
        <span class="detail-icon">15</span>
        <b>
          Даём рекомендации по уменьшению возможных риском при их наличии
        </b>
      </li>
    </ul>
    <div class="info">
      <div class="price">
        <p>Стоимость:</p>
        <h6>{{ cost }} ₽</h6>
      </div>
      <div class="term">
        <p>Срок исполнения:</p>
        <h6></h6>
      </div>
      <div class="format">
        <p>Формат:</p>
        <h6>Электронный документ</h6>
      </div>
    </div>
    <div class="bottom">
      <h5>Включает Отчеты по данным из ЕГРН</h5>
      <p>
        — Отчет по данным из ЕГРН об изменениях прав на объект недвижимости
      </p>
      <p>
        — Отчет по данным из ЕГРН об основных параметрах объекта недвижимости, в том числе
        проверка собственника (собственников), ограничение прав и обременения
      </p>
    </div>
  </div>
</template>

<script>
import popup from '@/mixins/popup';
import './checkboxDetail.scss';

export default {
  name: 'CheckboxDetailARS',
  mixins: [popup],
  computed: {
    cost() {
      return this.$store.state.config.cost.ARS;
    }
  }
};
</script>
